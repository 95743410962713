import React from 'react'
import "./Education.css"

const Education = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-lg p-lg-3">
                    <div className="row p-3">
                        <div className='IndustriesMain'>
                            <h2>
                                Our Expertise
                            </h2>
                            <h5>
                                Expertise in Education
                            </h5>
                            <p>
                                The educational sector is experiencing significant change. From preschool
                                to high school, and universities to technical training, educational institutions
                                must navigate changes across their operations and service delivery. Hybrid
                                and flexible learning models alter how students learn, and also how
                                faculty support new delivery mechanisms. Without question,
                                the need for rapid digital transformation in education has never been greater.
                            </p>
                            <p>
                                Industry leaders are challenged to improve service efficiency and
                                student outcomes without compromising the overall experience. Technology
                                can help drive these imperatives and create a future where institutions
                                deliver value in education, research and experience. But realizing this
                                future requires a partner with the experience and expertise to accelerate digital transformation in
                                education, enabling all stakeholders to achieve their desired outcomes.
                            </p>
                            <p>
                                Itsc can help education institutes around the world manage
                                their digital transformation journey by providing solutions that:
                            </p>
                            <p>
                                <ul>
                                    <li>Seamlessly blend in-person and remote learning strategies that align with new norms</li>
                                    <li>Effectively manage the student lifecycle experience and expectations</li>
                                    <li>Improve productivity by enhancing the faculty and employee experience</li>
                                    <li>Modernize infrastructure and provide critical support, significantly increasing overall effectiveness</li>
                                    <li>Leverage data to derive actionable insights and aid in decision making</li>
                                    <li>Drive innovation to improve learning and teaching outcomes</li>
                                </ul>
                            </p>
                            <p>
                                Itsc helps organizations design, customize, implement, manage and
                                improve a number of systems, from the student information system (SIS),
                                learning management system and CRM to workflow management systems,
                                HRMS and financial management systems. These diverse capabilities
                                reflect the full range of functions affected by digital transformation
                                in education. Through FullStride Cloud Services,
                                Itsc also enables full cloud migration and management.
                            </p>
                            <p>
                                Itsc’s consulting expertise and comprehensive solution portfolio
                                can build sustainable digital systems that increase student enrollment,
                                improve student/employee satisfaction,
                                drive learning outcomes and optimize operations for the new normal.
                            </p>
                            <h5>
                                An Extensive Partner Ecosystem
                            </h5>
                            <p>
                                Transforming the education value chain requires process and
                                domain excellence, across the student lifecycle, from prospect
                                to alumni. It also requires a comprehensive partner ecosystem
                                that enables greater flexibility to implement best-in-class
                                platforms for each unique situation or requirement. Itsc blends
                                in-house domain and technical expertise with a robust partner network to ensure digital
                                transformation in education and operation becomes a reality.
                            </p>
                            <p>
                                Some of the world’s top educational institutions have
                                envisioned, designed and delivered transformative results
                                with Itsc as their strategic partner. Let Itsc help you succeed in your
                                own digital transformation journey. Contact us today.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#e8e8e8" }}>
                    <div className="container-lg  CommanIndustriesStories">
                        <h2 className='CommanIndustries'>
                            Our Success Stories
                        </h2>
                        <div className="row  g-4">
                            <div className="col-lg-6 col-md-6">
                                <div className="row g-4">
                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Education1.png")} alt="" />
                                            <div className='content'>
                                                <h2> HARVARD TUMOR HUNT </h2>
                                                <p>
                                                    Improving lung cancer treatment through
                                                    AI-powered delineation algorithms
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Education5.png")} alt="" />
                                            <div className='content'>
                                                <h2> HARVARD TUMOR HUNT </h2>
                                                <p>
                                                    Improving lung cancer treatment through
                                                    AI-powered delineation algorithms
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="row g-4">
                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Education6.png")} alt="" />
                                            <div className='content'>
                                                <h2> The Great University Rethink of 2020  </h2>
                                                <p>
                                                    The moving parts of the university’s student experience need
                                                    to add up to helping the prospect make their choice in an...
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Education3.png")} alt="" />
                                            <div className='content'>
                                                <h2> Data Insights Fuel Faster Decisions </h2>
                                                <p>
                                                    Itsc helped a leading Australian university set up an
                                                    analytics platform to make informed decisions in the areas of...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section>
                <div className="container-fluid">
                    <div className="container-lg  CommanIndustriesStories">
                        <h2 className='CommanIndustries'>
                        What We Think
                        </h2>
                        <div className="row  g-4">
                            <div className="col-lg-6 col-md-6">
                                <div className="row g-4">
                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Education4.png")} alt="" />
                                            <div className='content'>
                                                <h2> HARVARD TUMOR HUNT </h2>
                                                <p>
                                                    Improving lung cancer treatment through
                                                    AI-powered delineation algorithms
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Education5.png")} alt="" />
                                            <div className='content'>
                                                <h2> HARVARD TUMOR HUNT </h2>
                                                <p>
                                                    Improving lung cancer treatment through
                                                    AI-powered delineation algorithms
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="row g-4">
                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Education6.png")} alt="" />
                                            <div className='content'>
                                                <h2> HARVARD TUMOR HUNT </h2>
                                                <p>
                                                    Improving lung cancer treatment through
                                                    AI-powered delineation algorithms
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Education7.png")} alt="" />
                                            <div className='content'>
                                                <h2> HARVARD TUMOR HUNT </h2>
                                                <p>
                                                    Improving lung cancer treatment through
                                                    AI-powered delineation algorithms
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

        </>
    )
}

export default Education
import React from 'react'
import { NavLink } from 'react-router-dom'

const NetworkingAndInfrastructure = () => {


    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services1.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    Networking and infrastructure solutions
                                </h2>
                                <p>
                                    Networking and infrastructure solutions involve the design,
                                    implementation, and maintenance of a computer network within
                                    an organization. This may include local area networks (LANs),
                                    wide area networks (WANs), and wireless networks, as well as
                                    the hardware and software components that support them.
                                    Networking and infrastructure solutions are essential for enabling
                                    communication and data sharing within an organization.
                                </p>
                                <p>
                                    Some examples of tasks that may be performed as
                                    part of networking and infrastructure solutions include:
                                </p>
                                <p>
                                    <ul>
                                        <li>Setting up and configuring network servers, routers, switches, and other equipment</li>
                                        <li>Installing and configuring network software, such as operating systems and applications</li>
                                        <li>Establishing network security measures, such as firewalls and user authentication</li>
                                        <li>Monitoring and troubleshooting network performance and issues</li>
                                        <li>Managing and maintaining network documentation, such as inventory lists and configuration records</li>
                                        <li>Implementing and managing virtualization solutions, such as virtual servers and virtual desktops</li>
                                        <li>Networking and infrastructure solutions are often critical to the smooth operation of an organization,
                                            as they enable the flow of information and support many business processes.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Itsc Solutions
                                </h2>
                                <NavLink className="text-decoration-none" to='/networkingandinfrastructure'>
                                    <p>
                                        Networking and Infrastructure Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/dmas'>
                                    <p>
                                        Data Management and Storage Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bpam'>
                                    <p>
                                        Business Process Automation and Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/css'>
                                    <p>
                                        Cyber Security Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/erp'>
                                    <p>
                                        Enterprise Resource Planning (ERP) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/crm'>
                                    <p>
                                        Customer Relationship Management (CRM) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/projectmanagementsolutions'>
                                    <p>
                                        Project Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bcdr'>
                                    <p>
                                        Disaster Recovery and Business Continuity Planning
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/wecomm'>
                                    <p>
                                        Website and E-commerce Solutions
                                    </p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default NetworkingAndInfrastructure
import React from 'react'
import { NavLink } from 'react-router-dom'

const Erp = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services5.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    Enterprise Resource Planning (ERP) Systems
                                </h2>
                                <p>
                                    Enterprise Resource Planning (ERP) systems are software applications
                                    that enable organizations to manage and integrate their business
                                    processes and functions, such as finance, accounting, HR, and operations.
                                    ERP systems provide a single, unified platform for storing and accessing
                                    data and information related to these processes,
                                    which can help to improve efficiency and decision making.
                                </p>
                                <p>
                                    ERP systems typically include modules for various business functions, such as:
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Financial management:</strong> accounting, budgeting, and financial reporting</li>
                                        <li><strong>Supply chain management:</strong> procurement, inventory management, and logistics</li>
                                        <li><strong>Human resources:</strong> employee records, payroll, and benefits</li>
                                        <li><strong>Customer relationship management:</strong> sales, marketing, and customer service</li>
                                        <li><strong>Project management:</strong> task assignment, scheduling, and tracking</li>
                                    </ul>
                                </p>
                                <p>
                                    ERP systems can be customized to meet the specific needs
                                    and requirements of an organization. They may be deployed
                                    on-premises or accessed through the cloud. Implementing
                                    an ERP system can be a complex and time-consuming process,
                                    as it involves integrating and standardizing various business
                                    processes and systems. However, it can also provide significant
                                    benefits, such as improved efficiency, better decision making,
                                    and increased visibility into business operations
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Itsc Solutions
                                </h2>
                                <NavLink className="text-decoration-none" to='/networkingandinfrastructure'>
                                    <p>
                                        Networking and Infrastructure Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/dmas'>
                                    <p>
                                        Data Management and Storage Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bpam'>
                                    <p>
                                        Business Process Automation and Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/css'>
                                    <p>
                                        Cyber Security Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/erp'>
                                    <p>
                                        Enterprise Resource Planning (ERP) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/crm'>
                                    <p>
                                        Customer Relationship Management (CRM) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/projectmanagementsolutions'>
                                    <p>
                                        Project Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bcdr'>
                                    <p>
                                        Disaster Recovery and Business Continuity Planning
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/wecomm'>
                                    <p>
                                        Website and E-commerce Solutions
                                    </p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Erp
import React from 'react'
import { NavLink } from 'react-router-dom'

const Crm = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services6.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    Customer Relationship Management (CRM) Systems
                                </h2>
                                <p>
                                    Customer Relationship Management (CRM) systems are software applications
                                    or platforms that enable organizations to manage and analyze customer
                                    interactions and data throughout the customer lifecycle.
                                    The goal of CRM is to improve customer relationships and support business growth.
                                </p>
                                <p>
                                    CRM systems typically include features such as:
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Contact management:</strong> storing and organizing customer contact information
                                            Sales and marketing automation: tracking and managing sales leads, campaigns, and customer interactions
                                        </li>
                                        <li><strong>Customer service and support:</strong> managing customer inquiries and complaints
                                            Analytics and reporting: generating reports and insights on customer behaviour and interactions
                                        </li>
                                    </ul>
                                </p>
                                <p>
                                    CRM systems can be used to support various customer-facing functions,
                                    such as sales, marketing, and customer service. They can help organizations
                                    to better understand their customers, improve communication and responsiveness,
                                    and increase customer loyalty.
                                    CRM systems may be deployed on-premises or accessed through the cloud.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Itsc Solutions
                                </h2>
                                <NavLink className="text-decoration-none" to='/networkingandinfrastructure'>
                                    <p>
                                        Networking and Infrastructure Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/dmas'>
                                    <p>
                                        Data Management and Storage Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bpam'>
                                    <p>
                                        Business Process Automation and Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/css'>
                                    <p>
                                        Cyber Security Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/erp'>
                                    <p>
                                        Enterprise Resource Planning (ERP) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/crm'>
                                    <p>
                                        Customer Relationship Management (CRM) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/projectmanagementsolutions'>
                                    <p>
                                        Project Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bcdr'>
                                    <p>
                                        Disaster Recovery and Business Continuity Planning
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/wecomm'>
                                    <p>
                                        Website and E-commerce Solutions
                                    </p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Crm
import React from 'react'
import { NavLink } from 'react-router-dom'

const BusinessProcessAutomation = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services3.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    Business Process Automation and Management Solutions
                                </h2>
                                <p>
                                    Business process automation and management solutions involve
                                    the use of technology to streamline and optimize business processes.
                                    These solutions can help organizations to reduce errors, improve efficiency,
                                    and increase productivity by automating repetitive or manual tasks.
                                </p>
                                <p>
                                    Some examples of tasks that may be automated using business
                                    process automation and management solutions include:
                                </p>
                                <p>
                                    <ul>
                                        <li>Data entry and management</li>
                                        <li>Invoice and payment processing</li>
                                        <li>Customer service and support</li>
                                        <li>Human resources and payroll management</li>
                                        <li>Project and task management</li>
                                        <li>Supply chain and logistics management</li>
                                    </ul>
                                </p>
                                <p>
                                    There are many different types of business process automation
                                    and management solutions available, including software applications,
                                    workflow tools, and artificial intelligence (AI) and machine learning systems.
                                    These solutions can be customized
                                    to meet the specific needs and goals of an organization.
                                </p>
                                <p>
                                    In addition to automating tasks, business process management
                                    solutions may also include tools for monitoring and analyzing business processes
                                    to identify opportunities for improvement and optimize performance.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Itsc Solutions
                                </h2>
                                <NavLink className="text-decoration-none" to='/networkingandinfrastructure'>
                                    <p>
                                        Networking and Infrastructure Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/dmas'>
                                    <p>
                                        Data Management and Storage Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bpam'>
                                    <p>
                                        Business Process Automation and Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/css'>
                                    <p>
                                        Cyber Security Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/erp'>
                                    <p>
                                        Enterprise Resource Planning (ERP) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/crm'>
                                    <p>
                                        Customer Relationship Management (CRM) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/projectmanagementsolutions'>
                                    <p>
                                        Project Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bcdr'>
                                    <p>
                                        Disaster Recovery and Business Continuity Planning
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/wecomm'>
                                    <p>
                                        Website and E-commerce Solutions
                                    </p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BusinessProcessAutomation
import React, { useState } from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { authActions } from "../../store";

const Login = () => {

    // const dispatch = useDispatch();

    const history = useNavigate();

    const [inputs, setInputes] = useState({
        name:"",
        email: "",
        password: ""
    });

    const handleChange = (e) => {
        setInputes(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    };

    const sendRequest = async () => {
        const res = await axios.post('http://localhost:3005/api/login', {
            email: inputs.email,
            password: inputs.password


        }).catch((err) => console.log(err));

        const data = await res.data;
        console.log(data)
        return data;
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        // sendRequest().then(() => dispatch(authActions.login())).then(() => history("/user"));
    };


    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container p-lg-5 p-2">
                        <div className="row  d-flex justify-content-center align-items-center">
                            <div className="col-lg-6  text-dark fw-bold merriweather-bold "  >
                                <form className='p-3' onSubmit={handleSubmit} style={{ backgroundColor: "#f6f6f6" }}>
                                    <div className='text-center pt-2'>
                                        <h2 className="mt-2"> Login</h2>
                                    </div>

                                    <div className="form-outline p-3">
                                        <label className="form-label laila-semibold" for="form2Example1">Name</label>
                                        <input type="text" id="form2Example1" className="form-control"
                                            name="name"
                                            onChange={handleChange}
                                            value={inputs.name} required />
                                    </div>

                                    <div className="form-outline p-3">
                                        <label className="form-label laila-semibold" for="form2Example1">Email address</label>
                                        <input type="email" id="form2Example1" className="form-control"
                                            name="email"
                                            onChange={handleChange}
                                            value={inputs.email} required />
                                    </div>

                                    <div className="form-outline p-3 ">
                                        <label className="form-label laila-semibold" for="form2Example2">Password</label>
                                        <input type="password" id="form2Example2" className="form-control"
                                            name="password"
                                            onChange={handleChange}
                                            value={inputs.password} required />
                                    </div>

                                    <div className=" p-3  h1FontFamily1">
                                        {/* <button type="submit" className=" p-2 border w-50 text-dark LOGINBUTTON    mt-3" ><b>LOGIN</b></button> */}
                                        <button type="submit" className="FooterReadMoreButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Login </b></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            

        </>
    )
}

export default Login
import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom';

const ItConsulting = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [lastname, setLastName] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.email = ""; errors.number = ""; errors.lastname = ""; errors.massage = "";

            var url = "https://wa.me/1271379270?text="
                + " Name : " + name + " Email : " + email + " Phone : " + number + " Last Name : " + lastname + " Message : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setName("");
        setEmail("");
        setNumber("");
        setLastName("");
        setMassage("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }

        if (!lastname) {
            errors.lastname = "Last Name Is Requried";
        }

        if (!number) {
            errors.number = "Phone Number Is Requried";
        }
        else if (number.length < 10) {
            errors.number = "Phone Number Is Requried "
        }
        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Comments Is Requried";
        }
        return errors;

    }

    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services2.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2>
                                    IT Consulting Services
                                </h2>
                                <h3>
                                    Providing thorough and complete IT roadmaps for small and mid-sized companies
                                </h3>
                                <p>
                                    Small and mid-sized businesses need to be efficient to be competitive.
                                    At ITSC, we deal in efficiency. We provide comprehensive IT consulting
                                    services to companies that are looking to leverage their company's IT into higher
                                    degrees of productivity, and ultimately, profitability.
                                    In order to take your business to the next level, you'll need to
                                    cut out the redundancies in process and in cost. The most successful companies
                                    will acknowledge the best ways to get the most out of the resources that are
                                    available to them. If your company is looking to enhance efficiency, and with it,
                                    boost organizational bottom-line profits, consider our professional IT consulting service.
                                    We have the knowledge and the expertise necessary to make your technology work best for you.
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Professional Technology Consultants. </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Flexible Solutions that Grow When You Do </b></span>
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Professional Technology Consultants.
                                </h2>
                                <h3>
                                    Our technicians are versed in all types of business technology.
                                </h3>
                                <p>
                                    The consultants at ITSC are extremely proficient at working with you
                                    to find business-minded solutions for any IT-related
                                    situation. We will create a customized IT
                                    roadmap that incorporates all types of information, such as:
                                </p>
                                <p>
                                    <ul>
                                        <li>Organizational computing requirements</li>
                                        <li>Present and future business strategies</li>
                                        <li>User needs and opinions</li>
                                        <li>Company guidelines and procedures</li>
                                    </ul>
                                </p>
                                <p>
                                    With this information in hand, our technicians can go to work implementing
                                    technology to meet your needs, thereby delivering to your company a
                                    complete computer infrastructure that is constructed specifically for your
                                    business. Our consulting services don't stop there. We
                                    provide ongoing consultations to ensure that your
                                    technology is meeting all of you and your staff's business needs.
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Flexible Solutions that Grow When You Do
                                </h2>
                                <h3>
                                    We take into account where you are going, not just where you are.
                                </h3>
                                <p>
                                    Improve the speed in which tasks get done by implementing technology
                                    enhancements. You can bring automation to many aspects of your business,
                                    and get more done, from more places, anytime; you just have to know where
                                    to start. Our consultants have knowledge of how to put together a solid IT
                                    infrastructure and are well versed in industry best practices. At ITSC, we
                                    are known as the top managed services provider in the British Columbia area
                                    and our IT solutions allow you to leverage your technology in a way that improves
                                    process and provides superior
                                    efficiency. Take your business above and beyond with ITSC.
                                </p>
                                <p>
                                    For more information about how the IT professionals at ITSC can help make
                                    technology work for you, call us at
                                    <NavLink className="text-decoration-none" to='tel:917410153100'>
                                        <span className='ms-2' style={{ color: "#01548d", fontWeight: "500" }}>+ 91 7410153100</span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Services
                                </h2>
                                <NavLink className="text-decoration-none" to='/itconsulting'>
                                    <p>
                                        IT Consulting
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/manageditservices'>
                                    <p>
                                        Managed Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/comprehensive'>
                                    <p>
                                        Comprehensive IT Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/remote'>
                                    <p>
                                        Remote Monitoring & Maintenance
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/itvendor'>
                                    <p>
                                        IT Vendor Management
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/infrastructure'>
                                    <p>
                                        Infrastructure Support Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/professional'>
                                    <p>
                                        Professional Server Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/staffingservices'>
                                    <p>
                                        Staffing Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/helpdesk'>
                                    <p>
                                        24/7 Help Desk
                                    </p>
                                </NavLink>
                            </div>

                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Questions?
                                </h2>
                                <p>
                                    Do you have questions about IT Services and what they can do for your company? Fill out this simple form with your questions and our team respond promptly!
                                </p>
                                <p>
                                    <form ref={form} onSubmit={loginHandle} className="row playfair-display g-4" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                        <div class="col-lg-12 ">
                                            <input type="text" placeholder='Name' className="form-control text-dark" style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputName" name="name"
                                                onChange={e => setName(e.target.value)} value={name}
                                            />
                                            {errors.name && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.name}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Last Name' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setLastName(e.target.value)} value={lastname}
                                            />
                                            {errors.lastname && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.lastname}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="email" placeholder='Email' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setEmail(e.target.value)} value={email}
                                            />
                                            {errors.email && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.email}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Phone' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setNumber(e.target.value)} value={number}
                                            />
                                            {errors.number && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.number}</div>}
                                        </div>

                                        <div className="col-lg-12">
                                            <textarea type="text" placeholder='Comments' className="form-control" style={{ borderRadius: "2px", height: "100px", backgroundColor: "#fff", border: "1px solid #9ACCC9", fontSize: "15px", color: "#555555" }} id="inputText3" name="message"
                                                onChange={e => setMassage(e.target.value)} value={massage}
                                            />
                                            {errors.massage && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.massage}</div>}
                                        </div>

                                        <div className="">
                                            <div className='d-flex justify-content-start mb-3'>
                                                <button type="submit" className="  FirstButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Conatct Us </b></button>
                                            </div>
                                        </div>
                                    </form>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ItConsulting
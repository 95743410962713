import React from 'react'
import { NavLink } from 'react-router-dom'

const DataManagementAndStorage = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services2.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    Data Management and Storage Solutions
                                </h2>
                                <p>
                                    Data management and storage solutions involve the design,
                                    implementation, and maintenance of systems that enable the storage,
                                    organization, and retrieval of data within an organization.
                                    These solutions are essential for enabling the efficient and effective use
                                    of data to support business operations and decision making.
                                </p>
                                <p>
                                    Some examples of tasks that may be performed as part of
                                    data management and storage solutions include:
                                </p>
                                <p>
                                    <ul>
                                        <li>Designing and implementing databases to store and organize data</li>
                                        <li>Setting up and configuring data storage systems, such as network-attached storage (NAS) or storage area networks (SANs)</li>
                                        <li>Establishing data backup and recovery processes to protect against data loss</li>
                                        <li>Implementing data security measures to protect against unauthorized access or tampering</li>
                                        <li>Monitoring and optimizing data storage performance and capacity</li>
                                        <li>Migrating data to new storage systems or platforms as needed</li>
                                    </ul>
                                </p>
                                <p>
                                    There are many different types of data management and
                                    storage solutions available, including both on-premises
                                    and cloud-based options. These solutions can vary in
                                    terms of the amount of storage capacity
                                    they offer, the level of security they provide,
                                    and the type of data they are designed to support.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Itsc Solutions
                                </h2>
                                <NavLink className="text-decoration-none" to='/networkingandinfrastructure'>
                                    <p>
                                        Networking and Infrastructure Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/dmas'>
                                    <p>
                                        Data Management and Storage Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bpam'>
                                    <p>
                                        Business Process Automation and Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/css'>
                                    <p>
                                        Cyber Security Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/erp'>
                                    <p>
                                        Enterprise Resource Planning (ERP) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/crm'>
                                    <p>
                                        Customer Relationship Management (CRM) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/projectmanagementsolutions'>
                                    <p>
                                        Project Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bcdr'>
                                    <p>
                                        Disaster Recovery and Business Continuity Planning
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/wecomm'>
                                    <p>
                                        Website and E-commerce Solutions
                                    </p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default DataManagementAndStorage
import React from 'react'
import { NavLink } from 'react-router-dom'

const Bcdr = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services8.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    Disaster Recovery and Business Continuity Planning
                                </h2>
                                <p>
                                    Disaster recovery and business continuity planning involve creating
                                    and implementing strategies and procedures to ensure that an organization
                                    can continue to operate in the event of a disaster or disruption.
                                    Disasters can include natural disasters, such as earthquakes and hurricanes, as well as
                                    human-caused disasters, such as cyber-attacks or power outages.
                                </p>
                                <p>
                                    Some examples of disaster recovery and business continuity measures include:
                                </p>
                                <p>
                                    <ul>
                                        <li>Backing up data and systems regularly to enable recovery in the event of data loss
                                            Implementing redundant systems and infrastructure to enable failover in the event of a failure
                                        </li>
                                        <li>Establishing procedures for communicating with employees and stakeholders during a disruption</li>
                                        <li>Identifying and securing alternative facilities or resources that can be used in the event that the primary facility or resources are unavailable</li>
                                        <li>Testing disaster recovery and business continuity plans regularly to ensure their effectiveness</li>
                                    </ul>
                                </p>
                                <p>
                                    Disaster recovery and business continuity planning is
                                    an important aspect of risk management for organizations.
                                    It helps to protect against data loss, minimize downtime,
                                    and ensure that critical business processes
                                    can continue in the event of a disruption.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Itsc Solutions
                                </h2>
                                <NavLink className="text-decoration-none" to='/networkingandinfrastructure'>
                                    <p>
                                        Networking and Infrastructure Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/dmas'>
                                    <p>
                                        Data Management and Storage Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bpam'>
                                    <p>
                                        Business Process Automation and Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/css'>
                                    <p>
                                        Cyber Security Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/erp'>
                                    <p>
                                        Enterprise Resource Planning (ERP) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/crm'>
                                    <p>
                                        Customer Relationship Management (CRM) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/projectmanagementsolutions'>
                                    <p>
                                        Project Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bcdr'>
                                    <p>
                                        Disaster Recovery and Business Continuity Planning
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/wecomm'>
                                    <p>
                                        Website and E-commerce Solutions
                                    </p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Bcdr
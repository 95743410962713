import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import "./Home.css"
import ScrollTrigger from "react-scroll-trigger"
import CountUp from 'react-countup';
// import { Bubble } from '@typebot.io/react'
// import { Bubble } from "@typebot.io/react";

const Home = () => {


    const [counterOn, setCounterOn] = useState(false);

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [company, setCompany] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});


    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.email = ""; errors.number = ""; errors.company = ""; errors.massage = "";

            var url = "https://wa.me/7410153100?text="
                + " Name : " + name + " Email : " + email + " Phone : " + number + " Company : " + company + " Message : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setName("");
        setEmail("");
        setNumber("");
        setCompany("");
        setMassage("");

    }


    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }

        if (!number) {
            errors.number = "Phone Number Is Requried";
        }
        else if (number.length < 10) {
            errors.number = "Phone Number Is Requried "
        }
        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Massage Is Requried";
        }
        return errors;

    }

    return (
        <>

            <section>
                <div className='container-fluid p-0 ' >
                    <div className='position-sticky ' style={{ zIndex: "-1", top: "0%" }}>
                        <video autoPlay loop muted className=' w-100' >
                            <source src={require("../Img/HomeBgVideo.mp4")} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

            </section>

            <section>
                <div className="container-lg CommanPadding">
                    <div className='ServicesHome'>
                        <h6>
                            Welcome To ITSC
                        </h6>
                        <div className='d-flex justify-content-center mb-3'>
                            <div className="" style={{ width: "5%", border: "2.2px solid #005792" }}>
                            </div>
                        </div>
                        <h3>
                            ITSC Makes Your <span> IT Infrastructure Our Priority. </span>
                        </h3>
                        <p>
                            Our team of experts can become your outsourced
                            IT department responding to issues quickly,
                            often before you even know about them.
                            Covering everything from your servers and network infrastructure,
                            to your computers, workstations and mobile devices,
                            we provide end-to-end solutions for all
                            of your technology needs.
                        </p>
                    </div>
                    <div className="row g-4 p-2">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className='MainServiceses'>
                                <i class="fas fa-chalkboard-teacher"></i>
                                <a className='text-decoration-none' href="/itconsulting">
                                    <h2>
                                        IT Consulting
                                    </h2>
                                </a>
                                <p>
                                    Save money, collaborate more effectively,
                                    and get more done by utilizing modern
                                    cloud IT solutions.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className='MainServiceses'>
                                <i class="fas fa-laptop"></i>
                                <a className='text-decoration-none' href="/manageditservices">
                                    <h2>
                                        Managed IT Services
                                    </h2>
                                </a>
                                <p>
                                    Stop dealing with IT headaches.
                                    We'll proactively maintain all of your technology
                                    so you can focus on your business.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className='MainServiceses'>
                                <i class="fa-solid fa-users-gear"></i>
                                <a className='text-decoration-none' href="/comprehensive">
                                    <h2>
                                        Comprehensive IT Support
                                    </h2>
                                </a>
                                <p>
                                    Protect your data, your employees,
                                    and your clients from the growing threat
                                    of cybersecurity attacks.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className='MainServiceses'>
                                <i class="fa-solid fa-user-group"></i>
                                <a className='text-decoration-none' href="/itvendor">
                                    <h2>
                                        IT Vendor Management
                                    </h2>
                                </a>
                                <p>
                                    Securely store and backup your data,
                                    and ensure that your business can always come back from a disaster.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#005792" }}>
                    <div className="container-lg FeatureMain">
                        <div className="row g-4 d-flex justify-content-center align-items-center MainText">
                            <div className="col-xl-10 col-md-9 ">
                                <div className='RecentPostTop'>
                                    <h2>
                                        Free Download
                                    </h2>
                                    <h3>
                                        The 10 Benefits of Managed IT Services
                                    </h3>
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-3">
                                <div className='RecentPostTop text-lg-center'>
                                    <button>
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className='AboutHome'>
                                <h6>
                                    About ITSC
                                </h6>
                                <div className='d-flex justify-content-start mb-3'>
                                    <div className="" style={{ width: "5%", border: "2.2px solid #005792" }}>
                                    </div>
                                </div>
                                <h3>
                                    Your Partner for Managed Services <span> & IT Support </span>
                                </h3>
                                <p>
                                    Whether you have problems to solve today or are
                                    looking to head off IT problems tomorrow, you need
                                    the right IT partner — one that provides the support you need,
                                    is close by, and can grow with you long term.
                                </p>
                                <p>
                                    With our flat monthly fee, you can forget about hourly rates,
                                    labour billing, fees for drive time, hidden charges, and
                                    unpredictable monthly bills. Instead, focus on your business.
                                    <b>We’ve got IT covered.</b>
                                </p>
                                <h4>
                                    CALL US NOW!
                                </h4>
                                <NavLink className="text-decoration-none" to='tel:917410153100'>
                                    <h5>
                                        7410153100
                                    </h5>
                                </NavLink>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 p-0">
                            <div>
                                <img className='CommonImg' src={require("../Img/AboutHomeSec.png")} alt="icloudsoft_logo" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 p-0">
                            <div>
                                <img className='CommonImg' src={require("../Img/AboutHomeSec2.png")} alt="icloudsoft_logo" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6" >
                            <div className='AboutHomeSecond'>
                                <h6>
                                    IT Services Done Differently
                                </h6>
                                <div className='d-flex justify-content-start mb-3'>
                                    <div className="" style={{ width: "5%", border: "2.2px solid #005792" }}>
                                    </div>
                                </div>
                                <h3>
                                    We're all in this <span> together </span>
                                </h3>
                                <h4>
                                    We're in this business to help other small businesses grow and move forward. It's as simple as that.
                                </h4>
                                <p>
                                    We're driven by the philosophy that if we help local
                                    businesses solve their IT issues so they can become
                                    more profitable and successful, then we will inherently
                                    grow along with them. There is no catch, no gimmick,
                                    just experience and best practices that we've honed
                                    over the years to make our services work for YOUR business needs.
                                </p>
                            </div>

                            <div>
                                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                    <div className="row gy-4 p-5 " >
                                        <div className="col-xl-6 ">
                                            <div className='MainWidth d-flex justify-content-lg-center align-items-center p-1' style={{ gap: "20px" }}>
                                                <div className='MainServicesesSecond'>
                                                    <i class="fa-solid fa-folder-open"></i>
                                                </div>

                                                <div className=''>
                                                    <div className='Saira CommonConter' style={{ fontSize: "30px", color: "#262c31", fontWeight: "800" }}> {counterOn && <CountUp start={0} end={65399} duration={2} delay={0}></CountUp>} </div>
                                                    <p className=' Saira' style={{ fontSize: "15px", lineHeight: "1", color: "#666", fontWeight: "500" }}>Tickets Completed</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-6 ">
                                            <div className='MainWidth d-flex justify-content-lg-center align-items-center p-1' style={{ gap: "20px" }}>
                                                <div className='MainServicesesSecond'>
                                                    <i class="fa-solid fa-users"></i>
                                                </div>

                                                <div className=''>
                                                    <div className='playfair-display' style={{ fontSize: "30px", color: "#262c31", fontWeight: "800" }}> {counterOn && <CountUp start={0} end={747} duration={2} delay={0}> </CountUp>} </div>
                                                    <p className=' Saira' style={{ fontSize: "15px", lineHeight: "1", color: "#666", fontWeight: "500" }}>Satisfied Customers</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-6 ">
                                            <div className='MainWidth d-flex justify-content-lg-center align-items-center p-1' style={{ gap: "20px" }} >
                                                <div className='MainServicesesSecond me-lg-5'>
                                                    <i class="fa-solid fa-award"></i>
                                                </div>

                                                <div className=''>
                                                    <div className='playfair-display' style={{ fontSize: "30px", color: "#262c31", fontWeight: "800" }}> {counterOn && <CountUp start={0} end={99} duration={2} delay={0}> </CountUp>} %</div>
                                                    <p className=' Saira' style={{ fontSize: "15px", lineHeight: "1", color: "#666", fontWeight: "500" }}>CSAT Score</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-6 ">
                                            <div className='MainWidth d-flex justify-content-lg-center align-items-center p-1' style={{ gap: "20px" }}>
                                                <div className='MainServicesesSecond'>
                                                    <i class="fa-solid fa-calendar"></i>
                                                </div>

                                                <div className=''>
                                                    <div className='playfair-display' style={{ fontSize: "30px", color: "#262c31", fontWeight: "800" }}> {counterOn && <CountUp start={0} end={24} duration={2} delay={0}> </CountUp>}</div>
                                                    <p className=' Saira' style={{ fontSize: "15px", lineHeight: "1", color: "#666", fontWeight: "500" }}>Years Experience</p>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </ScrollTrigger>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="container-lg mt-lg-5 FeatureMain">
                        <div className='ServicesHome'>
                            <h6>
                                Say Up To Date With The Latest News
                            </h6>
                            <div className='d-flex justify-content-center mb-3'>
                                <div className="" style={{ width: "5%", border: "2.2px solid #005792" }}>
                                </div>
                            </div>
                            <h3>
                                ITSC <span> Blog Posts </span>
                            </h3>
                        </div>

                        <div className="row d-flex justify-content-lg-center mt-3 g-4 ">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                <div>
                                    <div className='BlogCommonImg'>
                                        <img className='' src={require("../Img/BlogPost1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <div className='RecentPost-Content'>
                                        <h2>
                                            How a CRM Can Make Your Business Better
                                        </h2>
                                        <p>
                                            No matter your industry, size, or business model, satisfying your customers is crucial. Your busines...
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                <div>
                                    <div className='BlogCommonImg'>
                                        <img className='' src={require("../Img/BlogPost2.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <div className='RecentPost-Content'>
                                        <h2>
                                            Let’s Review Your Facebook Privacy Protections
                                        </h2>
                                        <p>
                                            Last week we went through some of the issues that Facebook is dealing with in regards to its securit...
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                <div className=''>
                                    <div className='BlogCommonImg'>
                                        <img className='' src={require("../Img/BlogPost3.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <div className='RecentPost-Content'>
                                        <h2>
                                            In the Wake of Hurricane Beryl, Consider Business Continuity
                                        </h2>
                                        <p>
                                            In the wake of Hurricane Beryl, businesses have another reason to reflect on data backup, disaster r...
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='ServicesBtn'>
                                <NavLink className='text-decoration-none' to='/services'>
                                    <button>
                                        View All
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#262c31" }}>
                    <div className="container-lg">
                        <div className="row g-4 d-flex justify-content-center align-items-center ">
                            <div className="col-lg-6 col-md-6">
                                <div className='LocationsHome'>
                                    <h6>
                                        Contact Details
                                    </h6>
                                    <div className='d-flex justify-content-start mb-3'>
                                        <div className="" style={{ width: "7%", border: "2.2px solid #005792" }}>
                                        </div>
                                    </div>
                                    <h2>
                                        office locations
                                    </h2>
                                    <h3>
                                        Working alongside clients on their most critical challenges.
                                    </h3>
                                    <p>
                                        <i class="fa-solid fa-location-dot"></i>
                                        <span>
                                            FLAT NO 20 528, NARAYAN PETH, PUNE 411030,  <br />
                                            41/4, DHANSHRI SOCIETY, NAVSAHYADRI SOCIETY,<br />
                                            Karve Nagar, Pune, Maharashtra 411052
                                        </span>
                                    </p>
                                    <p>
                                        Call us today <br />
                                        <NavLink className="text-decoration-none" to='tel:917410153100'>
                                            7410153100
                                        </NavLink>
                                    </p>

                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className='text-center pt-lg-5 pb-5'>
                                    <img className='w-100' src={require("../Img/coleman-map.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4">
                        <div className="col-lg-6 col-md-6 p-0">
                            <div>
                                <img className='CommonImg' src={require("../Img/HomeContactSec.png")} alt="icloudsoft_logo" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6" >
                            <div className='AboutHomeSecond'>
                                <h6>
                                    Contact ITSC
                                </h6>
                                <div className='d-flex justify-content-start mb-3'>
                                    <div className="" style={{ width: "5%", border: "2.2px solid #005792" }}>
                                    </div>
                                </div>
                                <h3 className='pt-2'>
                                    Start Resolving <span> Your IT Issues Today! </span>
                                </h3>

                                <div className=' pt-2'>
                                    <form ref={form} onSubmit={loginHandle} className="row playfair-display mt-3" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                        <div class="col-lg-12 ">
                                            <input type="text" placeholder='Name' className="form-control text-dark" style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputName" name="name"
                                                onChange={e => setName(e.target.value)} value={name}
                                            />
                                            {errors.name && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.name}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="email" placeholder='Email' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setEmail(e.target.value)} value={email}
                                            />
                                            {errors.email && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.email}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Phone' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setNumber(e.target.value)} value={number}
                                            />
                                            {errors.number && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.number}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Company Name' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setCompany(e.target.value)} value={company}
                                            />
                                            {errors.company && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.company}</div>}
                                        </div>

                                        <div className="col-lg-6">
                                            <textarea type="text" placeholder='Message' className="form-control" style={{ borderRadius: "2px", height: "100px", backgroundColor: "#fff", border: "1px solid #9ACCC9", fontSize: "15px", color: "#555555" }} id="inputText3" name="message"
                                                onChange={e => setMassage(e.target.value)} value={massage}
                                            />
                                            {errors.massage && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.massage}</div>}
                                        </div>

                                        <div className="">
                                            <div className='d-flex justify-content-start mb-3 mt-4'>
                                                <button type="submit" className="  FirstButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Send Message </b></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Home
import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'

const HelpDesk = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [lastname, setLastName] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.email = ""; errors.number = ""; errors.lastname = ""; errors.massage = "";

            var url = "https://wa.me/1271379270?text="
                + " Name : " + name + " Email : " + email + " Phone : " + number + " Last Name : " + lastname + " Message : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setName("");
        setEmail("");
        setNumber("");
        setLastName("");
        setMassage("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }

        if (!lastname) {
            errors.lastname = "Last Name Is Requried";
        }

        if (!number) {
            errors.number = "Phone Number Is Requried";
        }
        else if (number.length < 10) {
            errors.number = "Phone Number Is Requried "
        }
        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Comments Is Requried";
        }
        return errors;

    }

    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services8.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    24/7 Help Desk
                                </h2>
                                <p>
                                    Keep downtime from plaguing your business’ operational efficiency.
                                </p>
                                <p>
                                    ITSC is your one-stop shop for all of your
                                    IT support needs. Properly-functioning information technology
                                    is essential to the success of every business. When that IT isn’t
                                    working as it should, it can create a number of issues for your employees,
                                    and ultimately your business’ ability to complete projects,
                                    create revenue, and sustain efficient operations.
                                </p>
                                <p>
                                    Our knowledgeable and proficient technicians know that you
                                    need your IT up and running properly if you want to meet
                                    your expectations. That’s why we offer our comprehensive
                                    help desk service. If your IT is on the fritz, call one of
                                    our certified technicians 24 hours a day, seven days a week,
                                    365 days a year, and we will help your
                                    staff resolve their most annoying IT issues.
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Real-Time Expertise Just a Phone Call Away
                                </h2>
                                <h3>
                                    Our Technicians are Armed with Industry Leading Technology
                                </h3>
                                <p>
                                    As British Columbia small and medium-sized businesses have
                                    begun to implement some of today’s most powerful technology
                                    solutions to mitigate many of their company’s most pressing
                                    operational problems, they often demand that their IT staff
                                    have the availability to fix any problem that arises to avoid
                                    the costly downtime that can really sink the profitability
                                    of an organization. Whether they are made up of in-house technicians,
                                    or the trustworthy technicians from ITSC,
                                    you can now provide your end users with 24/7/365 technical
                                    support for all of their hardware and software issues.
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Mitigate Risk With Remote Support
                                </h2>
                                <h3>
                                    Solving IT problems does not need to put stress on your employees.
                                </h3>
                                <p>
                                    When technology doesn’t work as intended, it can be both
                                    a drain on productivity and employee morale. With our 24/7/365 Help Desk,
                                    employees always have a number to call that will quickly
                                    put them in touch with the IT
                                    support they need to solve their technology woes.
                                </p>
                                <p>
                                    In calling our comprehensive help desk for a resolution to
                                    your IT issues, our technicians will use our state-of-the-art
                                    remote monitoring and maintenance solution to securely gain
                                    remote access to the system in question. Our technicians are
                                    versed in ITIL best practices and can provide your organization the support that you need
                                    to keep your core technology functioning properly.
                                </p>
                                <p>
                                    Our help desk solution provides the following features:
                                </p>
                                <p>
                                    <ul>
                                        <li>24/7/365 support from certified friendly and knowledgeable technicians</li>
                                        <li>Fast and reliable call resolution</li>
                                        <li>Remote access and support</li>
                                        <li>Access to tiered support</li>
                                        <li>HIPAA and SSAE16 SOC2 compliant</li>
                                        <li>Indexed logs for critical IT services</li>
                                        <li>Technicians versed in ITIL best practices</li>
                                        <li>Support for Microsoft, Apple, and Linux</li>
                                    </ul>
                                </p>
                                <p>
                                    If you are searching for a solution to support your company’s
                                    crucial end-point technology, look no further than ITSC.
                                    If you would like more information about our 24/7 Help Desk and our other
                                    productivity-building managed IT services, contact us today at (604) 513-9428.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Services
                                </h2>
                                <NavLink className="text-decoration-none" to='/itconsulting'>
                                    <p>
                                        IT Consulting
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/manageditservices'>
                                    <p>
                                        Managed Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/comprehensive'>
                                    <p>
                                        Comprehensive IT Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/remote'>
                                    <p>
                                        Remote Monitoring & Maintenance
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/itvendor'>
                                    <p>
                                        IT Vendor Management
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/infrastructure'>
                                    <p>
                                        Infrastructure Support Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/professional'>
                                    <p>
                                        Professional Server Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/staffingservices'>
                                    <p>
                                        Staffing Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/helpdesk'>
                                    <p>
                                        24/7 Help Desk
                                    </p>
                                </NavLink>
                            </div>

                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Questions?
                                </h2>
                                <p>
                                    Do you have questions about IT Services and what they can do for your company? Fill out this simple form with your questions and our team respond promptly!
                                </p>
                                <p>
                                    <form ref={form} onSubmit={loginHandle} className="row playfair-display g-4" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                        <div class="col-lg-12 ">
                                            <input type="text" placeholder='Name' className="form-control text-dark" style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputName" name="name"
                                                onChange={e => setName(e.target.value)} value={name}
                                            />
                                            {errors.name && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.name}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Last Name' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setLastName(e.target.value)} value={lastname}
                                            />
                                            {errors.lastname && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.lastname}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="email" placeholder='Email' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setEmail(e.target.value)} value={email}
                                            />
                                            {errors.email && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.email}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Phone' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setNumber(e.target.value)} value={number}
                                            />
                                            {errors.number && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.number}</div>}
                                        </div>

                                        <div className="col-lg-12">
                                            <textarea type="text" placeholder='Comments' className="form-control" style={{ borderRadius: "2px", height: "100px", backgroundColor: "#fff", border: "1px solid #9ACCC9", fontSize: "15px", color: "#555555" }} id="inputText3" name="message"
                                                onChange={e => setMassage(e.target.value)} value={massage}
                                            />
                                            {errors.massage && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.massage}</div>}
                                        </div>

                                        <div className="">
                                            <div className='d-flex justify-content-start mb-3'>
                                                <button type="submit" className="  FirstButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Conatct Us </b></button>
                                            </div>
                                        </div>
                                    </form>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HelpDesk
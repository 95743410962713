import React from 'react'

const Error = () => {
    return (
        <div className='bg-light text-center bg-dark' style={{ height: "50vh" }}>
            <div className="container p-5 pt-5">
                <div className="row error-container pt-3">
                    <div className="col-md-12 text-center pt-5">
                        <h6 className="error-heading fs-1 text-white">404</h6>
                        <p className="error-text text-white">Oops! The page you are looking for could not be found.</p>
                        <a className="text-decoration-none btn btn-lg btn-back fs-5 bg-info" href="/" >Go Back</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error
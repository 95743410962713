import React from 'react'
import "./Services.css"

const Services = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-lg CommanPadding">
                    <div className='ServicesHome'>
                        <h3>
                            ITSC IT | <span className='' style={{ color: "#01548d" }}> Services Overview </span>
                        </h3>
                        <p>
                            Know what you’re looking for? Select the
                            IT service category below to find out more
                            information!  If you are looking for answers
                            that you can’t find below, feel free to
                            contact us!
                        </p>
                    </div>
                    <div className="row g-4 p-2 pt-lg-4 pb-4">

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className='ServicesMainServiceses'>
                                <i class="fas fa-chalkboard-teacher"></i>
                                <a className="text-decoration-none" href='/itconsulting'>
                                    <h2>
                                        IT Consulting
                                    </h2>
                                </a>
                                <p>
                                    The cloud is the future of small and medium
                                    business technology management.
                                </p>
                                <a className="text-decoration-none" href='/itconsulting'>
                                    <div className='d-flex justify-content-center'>
                                        <button className="FooterReadMoreButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Read More </b></button>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className='ServicesMainServiceses '>
                                <i class="fas fa-laptop"></i>
                                <a className="text-decoration-none" href='/manageditservices'>
                                    <h2>
                                        Managed IT Services
                                    </h2>
                                </a>
                                <p>
                                    Strategically optimize and protect your
                                    infrastructure with 24/7/365 Proactive Monitoring.
                                </p>
                                <a className="text-decoration-none" href='/manageditservices'>
                                    <div className='d-flex justify-content-center'>
                                        <button className="FooterReadMoreButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Read More </b></button>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className='ServicesMainServiceses'>
                                <i class="fa-solid fa-users-gear"></i>
                                <a className="text-decoration-none" href='/comprehensive'>
                                    <h2>
                                        Comprehensive IT Support
                                    </h2>
                                </a>
                                <p>
                                    Advanced security systems help protect
                                    your data and keep your business up and running.
                                </p>
                                <a className="text-decoration-none" href='/comprehensive'>
                                    <div className='d-flex justify-content-center'>
                                        <button className="FooterReadMoreButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Read More </b></button>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className='ServicesMainServiceses'>
                                <i class="fa-solid fa-network-wired"></i>
                                <a className="text-decoration-none" href='/remote'>
                                    <h2>
                                        Remote Monitoring & Maintenance
                                    </h2>
                                </a>
                                <p>
                                    Looking for IT Solutions specific to
                                    your Industry?
                                </p>
                                <a className="text-decoration-none" href='/remote'>
                                    <div className='d-flex justify-content-center'>
                                        <button className="FooterReadMoreButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Read More </b></button>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className='ServicesMainServiceses'>
                                <i class="fa-solid fa-user-group"></i>
                                <a className="text-decoration-none" href='/itvendor'>
                                    <h2>
                                        IT Vendor Management
                                    </h2>
                                </a>
                                <p>
                                    Is your data secure? Would it survive a
                                    disaster? Are you willing to take that chance?
                                </p>
                                <a className="text-decoration-none" href='/itvendor'>
                                    <div className='d-flex justify-content-center'>
                                        <button className="FooterReadMoreButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Read More </b></button>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className='ServicesMainServiceses'>
                                <i class="fa-sharp fa-solid fa-battery-three-quarters"></i>
                                <a className="text-decoration-none" href='/infrastructure'>
                                    <h2>
                                        Infrastructure Support Services
                                    </h2>
                                </a>
                                <p>
                                    Can your enterprise afford to replace
                                    the essential hardware?
                                </p>
                                <a className="text-decoration-none" href='/infrastructure'>
                                    <div className='d-flex justify-content-center'>
                                        <button className="FooterReadMoreButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Read More </b></button>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className='ServicesMainServiceses'>
                                <i class="fa-sharp fa-solid fa-server"></i>
                                <a className="text-decoration-none" href='/professional'>
                                    <h2>
                                        Professional Server Support
                                    </h2>
                                </a>
                                <p>
                                    Have every team member collaborate on the
                                    same project from any location!
                                </p>
                                <a className="text-decoration-none" href='/professional'>
                                    <div className='d-flex justify-content-center'>
                                        <button className="FooterReadMoreButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Read More </b></button>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className='ServicesMainServiceses'>
                                <i class="fa-sharp fa-solid fa-users"></i>
                                <a className="text-decoration-none" href='/staffingservices'>
                                    <h2>
                                        Staffing Services
                                    </h2>
                                </a>
                                <p>
                                    Empower your business to go far beyond the
                                    restraints of a typical phone system.
                                </p>
                                <a className="text-decoration-none" href='/staffingservices'>
                                    <div className='d-flex justify-content-center'>
                                        <button className="FooterReadMoreButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Read More </b></button>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className='ServicesMainServiceses'>
                                <i class="fa-solid fa-headphones"></i>
                                <a className="text-decoration-none" href='/helpdesk'>
                                    <h2>
                                        24/7 Help Desk
                                    </h2>
                                </a>
                                <p>

                                    Improving your company's presence on the
                                    internet is essential.
                                </p>
                                <a className="text-decoration-none" href='/helpdesk'>
                                    <div className='d-flex justify-content-center'>
                                        <button className="FooterReadMoreButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Read More </b></button>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div >
            </section >

        </>
    )
}

export default Services
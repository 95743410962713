import React from 'react'
import { NavLink } from 'react-router-dom'

const CyberSecuritySolutions = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services4.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    Cyber Security solutions
                                </h2>
                                <p>
                                    There are many different types of cybersecurity solutions available, including:
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Firewalls:</strong> hardware or software systems that block unauthorized access to a network</li>
                                        <li><strong>Anti-virus and anti-malware software:</strong> tools that detect and remove malware from systems</li>
                                        <li><strong>Encryption:</strong> methods for encoding data to protect it from unauthorized access</li>
                                        <li><strong>Identity and access management:</strong> systems that control and monitor access to resources</li>
                                        <li><strong>Network security:</strong> measures to protect against threats to a network, such as denial of service attacks</li>
                                        <li><strong>Vulnerability management:</strong> processes for identifying and mitigating vulnerabilities in systems and networks</li>
                                    </ul>
                                </p>
                                <p>
                                    Implementing cybersecurity solutions is an important aspect of
                                    protecting an organization's information and assets. Cybersecurity
                                    professionals, such as security analysts and administrators,
                                    are responsible for designing and implementing these solutions.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Itsc Solutions
                                </h2>
                                <NavLink className="text-decoration-none" to='/networkingandinfrastructure'>
                                    <p>
                                        Networking and Infrastructure Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/dmas'>
                                    <p>
                                        Data Management and Storage Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bpam'>
                                    <p>
                                        Business Process Automation and Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/css'>
                                    <p>
                                        Cyber Security Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/erp'>
                                    <p>
                                        Enterprise Resource Planning (ERP) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/crm'>
                                    <p>
                                        Customer Relationship Management (CRM) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/projectmanagementsolutions'>
                                    <p>
                                        Project Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bcdr'>
                                    <p>
                                        Disaster Recovery and Business Continuity Planning
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/wecomm'>
                                    <p>
                                        Website and E-commerce Solutions
                                    </p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CyberSecuritySolutions
import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom';

const Comprehensive = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [lastname, setLastName] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.email = ""; errors.number = ""; errors.lastname = ""; errors.massage = "";

            var url = "https://wa.me/1271379270?text="
                + " Name : " + name + " Email : " + email + " Phone : " + number + " Last Name : " + lastname + " Message : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setName("");
        setEmail("");
        setNumber("");
        setLastName("");
        setMassage("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }

        if (!lastname) {
            errors.lastname = "Last Name Is Requried";
        }

        if (!number) {
            errors.number = "Phone Number Is Requried";
        }
        else if (number.length < 10) {
            errors.number = "Phone Number Is Requried "
        }
        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Comments Is Requried";
        }
        return errors;

    }

    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services3.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    Comprehensive IT Support
                                </h2>
                                <h3>
                                    Computer, Laptop, Server, Printers, CCTV and network support for MSME.
                                </h3>
                                <p>
                                    Downtime is a profit killer. At ITSC, we recognize that in
                                    order to maximize profitability, your company’s IT has to
                                    be up-and-running. That’s why we provide comprehensive IT
                                    support to small and midsized businesses. Our support services
                                    are delivered in an affordable, monthly rate based on the hardware
                                    you have in place. Take advantage of optimal uptime and experience
                                    for yourself the benefits that ITSC's
                                    managed IT and computer, Laptop, Server, Printers,
                                    CCTV repair services present businesses like yours.
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Computer Hardware Support </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Software Licensing and Management </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Support Desk </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Monitor Desktops for Issues </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Automatic Deployment of Patches and Security Updates </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Manage Resources Efficiently </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Desktop Monitoring and Management Solutions </b></span>
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Computer Hardware Support
                                </h2>
                                <h3>
                                    If your hardware breaks, our tech support is there to fix it!
                                </h3>
                                <p>
                                    Your computers will fail. Through wear-and-tear, any system’s
                                    internal components will eventually break down, leaving you to
                                    pick up the pieces. Fortunately for you, the IT professionals at
                                    ITSC possess the expertise to provide a thorough
                                    analysis of almost any piece of computing hardware or accessory.
                                    Our ability to determine the root of a problem, find a solution
                                    for the problem, and implement a solution sets us apart from the
                                    competition. With our fast and accurate analysis, we are then able
                                    to fix or replace the malfunctioning piece of
                                    hardware and get it working again quickly, limiting downtime.
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Software Licensing and Management
                                </h2>
                                <h3>
                                    Experience the most effective day-to-day operations with up to date software.
                                </h3>
                                <p>
                                    In the information age, software drives business. Software
                                    companies know this. In order to keep running the most current
                                    versions of productivity or management software, you will need
                                    to acquire the licenses needed to accommodate the computing your
                                    staff needs. At ITSC, we take
                                    handle software licensing to make sure that your
                                    company’s software is up to date and delivered properly.
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Support Desk
                                </h2>
                                <h3>
                                    Your company’s personal IT hotline!
                                </h3>
                                <p>
                                    Whenever your employees experience a major IT issue, it takes
                                    a serious toll on their ability to be productive. This is especially
                                    true if the problem exceeds the technical knowledge or capabilities
                                    of your business’ “go-to” IT person. To remedy this, Coleman
                                    Technologies offers Help Desk Support as an
                                    optional part of our Comprehensive IT support service.
                                </p>
                                <p>
                                    Anytime that your employees experience difficulty with their
                                    technology, we are one phone call away. Our
                                    professional IT technicians will be able to assist them
                                    and find a solution for whatever IT problems they have.
                                </p>
                                <p>
                                    For more information about how ITSC's
                                    professional IT technicians can present your organization
                                    with a high-degree of value, please take a minute and
                                    fill out the form on the right-hand portion of the screen,
                                    or simply give us a call at (604) 513-9428.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Services
                                </h2>
                                <NavLink className="text-decoration-none" to='/itconsulting'>
                                    <p>
                                        IT Consulting
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/manageditservices'>
                                    <p>
                                        Managed Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/comprehensive'>
                                    <p>
                                        Comprehensive IT Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/remote'>
                                    <p>
                                        Remote Monitoring & Maintenance
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/itvendor'>
                                    <p>
                                        IT Vendor Management
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/infrastructure'>
                                    <p>
                                        Infrastructure Support Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/professional'>
                                    <p>
                                        Professional Server Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/staffingservices'>
                                    <p>
                                        Staffing Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/helpdesk'>
                                    <p>
                                        24/7 Help Desk
                                    </p>
                                </NavLink>
                            </div>

                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Questions?
                                </h2>
                                <p>
                                    Do you have questions about IT Services and what they can do for your company? Fill out this simple form with your questions and our team respond promptly!
                                </p>
                                <p>
                                    <form ref={form} onSubmit={loginHandle} className="row playfair-display g-4" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                        <div class="col-lg-12 ">
                                            <input type="text" placeholder='Name' className="form-control text-dark" style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputName" name="name"
                                                onChange={e => setName(e.target.value)} value={name}
                                            />
                                            {errors.name && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.name}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Last Name' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setLastName(e.target.value)} value={lastname}
                                            />
                                            {errors.lastname && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.lastname}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="email" placeholder='Email' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setEmail(e.target.value)} value={email}
                                            />
                                            {errors.email && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.email}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Phone' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setNumber(e.target.value)} value={number}
                                            />
                                            {errors.number && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.number}</div>}
                                        </div>

                                        <div className="col-lg-12">
                                            <textarea type="text" placeholder='Comments' className="form-control" style={{ borderRadius: "2px", height: "100px", backgroundColor: "#fff", border: "1px solid #9ACCC9", fontSize: "15px", color: "#555555" }} id="inputText3" name="message"
                                                onChange={e => setMassage(e.target.value)} value={massage}
                                            />
                                            {errors.massage && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.massage}</div>}
                                        </div>

                                        <div className="">
                                            <div className='d-flex justify-content-start mb-3'>
                                                <button type="submit" className="  FirstButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Conatct Us </b></button>
                                            </div>
                                        </div>
                                    </form>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Comprehensive
import React from 'react'
import { NavLink } from 'react-router-dom'

const ProjectManagementSolutions = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services7.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    Project Management Solutions
                                </h2>
                                <p>
                                    Project management solutions are tools or platforms that enable
                                    organizations to plan, execute, and track projects. These solutions
                                    can help organizations to define project scope, assign tasks and
                                    resources, track progress, and manage budgets and timelines.
                                </p>
                                <p>
                                    Some examples of project management solutions include:
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Project management software:</strong> tools that enable users to create project plans, assign tasks, track progress, and manage resources</li>
                                        <li><strong>Project portfolio management (PPM) software:</strong> tools that enable organizations to prioritize and manage multiple projects and resources</li>
                                        <li><strong>Workflow management software:</strong> tools that enable users to automate and optimize business processes and workflows</li>
                                    </ul>
                                </p>
                                <p>
                                    Project management solutions can be used for a wide range of projects,
                                    including IT projects, marketing campaigns, and product development.
                                    They can help organizations to improve efficiency and productivity,
                                    reduce errors and risk, and deliver projects on time and within budget.
                                    Project management professionals, such as project managers and coordinators,
                                    are responsible for planning and overseeing the use of these solutions.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Itsc Solutions
                                </h2>
                                <NavLink className="text-decoration-none" to='/networkingandinfrastructure'>
                                    <p>
                                        Networking and Infrastructure Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/dmas'>
                                    <p>
                                        Data Management and Storage Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bpam'>
                                    <p>
                                        Business Process Automation and Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/css'>
                                    <p>
                                        Cyber Security Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/erp'>
                                    <p>
                                        Enterprise Resource Planning (ERP) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/crm'>
                                    <p>
                                        Customer Relationship Management (CRM) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/projectmanagementsolutions'>
                                    <p>
                                        Project Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bcdr'>
                                    <p>
                                        Disaster Recovery and Business Continuity Planning
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/wecomm'>
                                    <p>
                                        Website and E-commerce Solutions
                                    </p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProjectManagementSolutions
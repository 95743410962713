import React from 'react'
import { NavLink } from 'react-router-dom'

const WebsiteAndEcommerce = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services9.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    Website and E-commerce Solutions
                                </h2>
                                <p>
                                    Website and e-commerce solutions involve the design, development,
                                    and maintenance of websites and online platforms that enable
                                    organizations to sell products or services online.
                                </p>
                                <p>
                                    These solutions can include:
                                </p>
                                <p>
                                    <ul>
                                        <li><strong>Website design and development:</strong> creating and building a website that reflects the branding and messaging of an organization</li>
                                        <li><strong>Content management systems (CMS):</strong> tools that enable users to create, edit, and publish content on a website</li>
                                        <li><strong>E-commerce platforms:</strong> software that enables organizations to sell products or services online, including features such as shopping carts, payment processing, and order management</li>
                                        <li><strong>Marketing and SEO tools:</strong> tools that enable organizations to optimize their website for search engines and track website traffic and performance</li>
                                        <li><strong>Customer service and support tools:</strong> tools that enable organizations to interact with and support customers online, such as live chat or email</li>
                                    </ul>
                                </p>
                                <p>
                                    Website and e-commerce solutions are essential for organizations
                                    that want to establish an online presence or sell products or
                                    services online. They can help to improve customer
                                    engagement and sales, and provide a convenient and
                                    accessible way for customers to interact with an organization.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Itsc Solutions
                                </h2>
                                <NavLink className="text-decoration-none" to='/networkingandinfrastructure'>
                                    <p>
                                        Networking and Infrastructure Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/dmas'>
                                    <p>
                                        Data Management and Storage Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bpam'>
                                    <p>
                                        Business Process Automation and Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/css'>
                                    <p>
                                        Cyber Security Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/erp'>
                                    <p>
                                        Enterprise Resource Planning (ERP) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/crm'>
                                    <p>
                                        Customer Relationship Management (CRM) Systems
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/projectmanagementsolutions'>
                                    <p>
                                        Project Management Solutions
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/bcdr'>
                                    <p>
                                        Disaster Recovery and Business Continuity Planning
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/wecomm'>
                                    <p>
                                        Website and E-commerce Solutions
                                    </p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default WebsiteAndEcommerce
import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Hardware.css"

const Hardware = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-lg p-4 merriweather-bold"  >
                    <div className='p-3' >
                        <h3 className='' style={{fontWeight: "600", fontSize: "20px" }} >
                            DELL LAPTOPS
                        </h3>
                    </div>
                    <div className="row g-4 p-lg-2"   >
                        <div className="col-lg-3 col-md-6 mb-lg-5">
                            <NavLink className='text-decoration-none' to='/'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100' src={require("../Img/AlienwareGamingLaptop.avif")} alt='works-at-iclodsoft' />
                                    </div>

                                    <div className='ProductText mt-2'>
                                        <h2 className='' >
                                            Price: $ 109.5
                                        </h2>

                                        <h3>
                                            Alienware m16 R2 Gaming Laptop
                                        </h3>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        

                    </div>
                </div>
            </section >

        </>
    )
}

export default Hardware